import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class AdminAuthService {
  constructor(@Inject(PLATFORM_ID) public platformId: Object) { }

  loggedInAsAdmin() {
    let hasToken = false;
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem('adminToken') == 'secretAdminToken') {
        hasToken = true;
      }
    }
    return hasToken;
  }

  getToken() {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem('adminToken');
    }
  }

  logout() {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem('adminToken');
    }
  }
}

@Injectable()
export class AdminAuthGuard {
  constructor(
    private _authService: AdminAuthService,
    private router: Router
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this._authService.loggedInAsAdmin()) {
      return true;
    } else {
      this.router.navigate(['/admin-login'], { queryParams: { returnUrl: state.url } });
      return false;
    }
  }
}
